import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["GetAuthor","PostType","PostStatus","BlogArticle","BlogArticlesPreviewsFiltered","BlogArticlesPreviews","BlogArticlesByAuthor","CaseStudiesArticle","AnalystReportsArticle","InstallationArticle","BlogArticleFeatured","Categories","Category","GetEvent","EventCode","GetEvents","GetEventsDates","RelatedGuides","MemberCards","Page"]}
export const GqlAnalystReportsArticle = (...params) => useGql()('AnalystReportsArticle', ...params)
export const GqlBlogArticle = (...params) => useGql()('BlogArticle', ...params)
export const GqlBlogArticleFeatured = (...params) => useGql()('BlogArticleFeatured', ...params)
export const GqlBlogArticlesByAuthor = (...params) => useGql()('BlogArticlesByAuthor', ...params)
export const GqlBlogArticlesPreviews = (...params) => useGql()('BlogArticlesPreviews', ...params)
export const GqlBlogArticlesPreviewsFiltered = (...params) => useGql()('BlogArticlesPreviewsFiltered', ...params)
export const GqlCaseStudiesArticle = (...params) => useGql()('CaseStudiesArticle', ...params)
export const GqlCategories = (...params) => useGql()('Categories', ...params)
export const GqlCategory = (...params) => useGql()('Category', ...params)
export const GqlEventCode = (...params) => useGql()('EventCode', ...params)
export const GqlGetAuthor = (...params) => useGql()('GetAuthor', ...params)
export const GqlGetEvent = (...params) => useGql()('GetEvent', ...params)
export const GqlGetEvents = (...params) => useGql()('GetEvents', ...params)
export const GqlGetEventsDates = (...params) => useGql()('GetEventsDates', ...params)
export const GqlInstallationArticle = (...params) => useGql()('InstallationArticle', ...params)
export const GqlMemberCards = (...params) => useGql()('MemberCards', ...params)
export const GqlPage = (...params) => useGql()('Page', ...params)
export const GqlPostStatus = (...params) => useGql()('PostStatus', ...params)
export const GqlPostType = (...params) => useGql()('PostType', ...params)
export const GqlRelatedGuides = (...params) => useGql()('RelatedGuides', ...params)