import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgInstagram, LazySvgOOTBI, LazySvgAce, LazySvgArrowBackward, LazySvgArrowDownFill, LazySvgArrowDown, LazySvgArrowForward, LazySvgArrowRightUp, LazySvgArrowUpFill, LazySvgAvailabilityColorfull, LazySvgBestStorageForVeeam, LazySvgBurger, LazySvgCalendarColorfull, LazySvgCalendarToday, LazySvgChartColorfull, LazySvgCheckInCircleFill, LazySvgCheckInCircleFilled, LazySvgCheckInCircle, LazySvgCheck, LazySvgChevronDown, LazySvgChevronLeft, LazySvgChevronRight, LazySvgChevronUp, LazySvgClock, LazySvgClose, LazySvgCloudUpdateBw, LazySvgContents, LazySvgDartsColorfull, LazySvgDownloadBox, LazySvgDownloadBw, LazySvgDownload, LazySvgEye, LazySvgFacebook, LazySvgFileColorfull, LazySvgGear, LazySvgGithub, LazySvgHeadsetColorfull, LazySvgHeadset, LazySvgHeartColorfull, LazySvgHome, LazySvgImmutabilityColorfull, LazySvgInfoOutline, LazySvgLightbulb, LazySvgLikeBw, LazySvgLikeColorfull, LazySvgLink, LazySvgLinkedIn, LazySvgLiveDemoColorfull, LazySvgLiveDemo, LazySvgLoad, LazySvgLocation, LazySvgLockBw, LazySvgLockColorfull, LazySvgLogoHelp, LazySvgLogo, LazySvgMinus, LazySvgOfColorfull, LazySvgOnPermToCloudColorfull, LazySvgPdfFile, LazySvgPlanet, LazySvgPlayArrow, LazySvgPlus, LazySvgReliabilityBw, LazySvgReliabilityColorfull, LazySvgRocketBw, LazySvgRocketColorfull, LazySvgScalabilityColorfull, LazySvgSearch, LazySvgShieldCrossedOutBw, LazySvgSpinner, LazySvgStorageDisks, LazySvgStorage, LazySvgUpToDateColorfull, LazySvgX, LazySvgYoutube, LazySvgZoomClose, LazySvgZoomIn } from '#components'
const lazyGlobalComponents = [
  ["SvgInstagram", LazySvgInstagram],
["SvgOOTBI", LazySvgOOTBI],
["SvgAce", LazySvgAce],
["SvgArrowBackward", LazySvgArrowBackward],
["SvgArrowDownFill", LazySvgArrowDownFill],
["SvgArrowDown", LazySvgArrowDown],
["SvgArrowForward", LazySvgArrowForward],
["SvgArrowRightUp", LazySvgArrowRightUp],
["SvgArrowUpFill", LazySvgArrowUpFill],
["SvgAvailabilityColorfull", LazySvgAvailabilityColorfull],
["SvgBestStorageForVeeam", LazySvgBestStorageForVeeam],
["SvgBurger", LazySvgBurger],
["SvgCalendarColorfull", LazySvgCalendarColorfull],
["SvgCalendarToday", LazySvgCalendarToday],
["SvgChartColorfull", LazySvgChartColorfull],
["SvgCheckInCircleFill", LazySvgCheckInCircleFill],
["SvgCheckInCircleFilled", LazySvgCheckInCircleFilled],
["SvgCheckInCircle", LazySvgCheckInCircle],
["SvgCheck", LazySvgCheck],
["SvgChevronDown", LazySvgChevronDown],
["SvgChevronLeft", LazySvgChevronLeft],
["SvgChevronRight", LazySvgChevronRight],
["SvgChevronUp", LazySvgChevronUp],
["SvgClock", LazySvgClock],
["SvgClose", LazySvgClose],
["SvgCloudUpdateBw", LazySvgCloudUpdateBw],
["SvgContents", LazySvgContents],
["SvgDartsColorfull", LazySvgDartsColorfull],
["SvgDownloadBox", LazySvgDownloadBox],
["SvgDownloadBw", LazySvgDownloadBw],
["SvgDownload", LazySvgDownload],
["SvgEye", LazySvgEye],
["SvgFacebook", LazySvgFacebook],
["SvgFileColorfull", LazySvgFileColorfull],
["SvgGear", LazySvgGear],
["SvgGithub", LazySvgGithub],
["SvgHeadsetColorfull", LazySvgHeadsetColorfull],
["SvgHeadset", LazySvgHeadset],
["SvgHeartColorfull", LazySvgHeartColorfull],
["SvgHome", LazySvgHome],
["SvgImmutabilityColorfull", LazySvgImmutabilityColorfull],
["SvgInfoOutline", LazySvgInfoOutline],
["SvgLightbulb", LazySvgLightbulb],
["SvgLikeBw", LazySvgLikeBw],
["SvgLikeColorfull", LazySvgLikeColorfull],
["SvgLink", LazySvgLink],
["SvgLinkedIn", LazySvgLinkedIn],
["SvgLiveDemoColorfull", LazySvgLiveDemoColorfull],
["SvgLiveDemo", LazySvgLiveDemo],
["SvgLoad", LazySvgLoad],
["SvgLocation", LazySvgLocation],
["SvgLockBw", LazySvgLockBw],
["SvgLockColorfull", LazySvgLockColorfull],
["SvgLogoHelp", LazySvgLogoHelp],
["SvgLogo", LazySvgLogo],
["SvgMinus", LazySvgMinus],
["SvgOfColorfull", LazySvgOfColorfull],
["SvgOnPermToCloudColorfull", LazySvgOnPermToCloudColorfull],
["SvgPdfFile", LazySvgPdfFile],
["SvgPlanet", LazySvgPlanet],
["SvgPlayArrow", LazySvgPlayArrow],
["SvgPlus", LazySvgPlus],
["SvgReliabilityBw", LazySvgReliabilityBw],
["SvgReliabilityColorfull", LazySvgReliabilityColorfull],
["SvgRocketBw", LazySvgRocketBw],
["SvgRocketColorfull", LazySvgRocketColorfull],
["SvgScalabilityColorfull", LazySvgScalabilityColorfull],
["SvgSearch", LazySvgSearch],
["SvgShieldCrossedOutBw", LazySvgShieldCrossedOutBw],
["SvgSpinner", LazySvgSpinner],
["SvgStorageDisks", LazySvgStorageDisks],
["SvgStorage", LazySvgStorage],
["SvgUpToDateColorfull", LazySvgUpToDateColorfull],
["SvgX", LazySvgX],
["SvgYoutube", LazySvgYoutube],
["SvgZoomClose", LazySvgZoomClose],
["SvgZoomIn", LazySvgZoomIn],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
