import type { Locale } from '#i18n';
import removeLocaleFromPath from '~/utils/removeLocaleFromPath.js';

export default function useSwitchLocaleWithSlash(): (value: string, locale?: Locale) => string {
  const switchLocalePath = useSwitchLocalePath();

  return (locale) => {
    let path = switchLocalePath(locale);

    const [cleanPath, queryParams] = path.split('?');

    path = cleanPath.endsWith('/') || removeLocaleFromPath(cleanPath) === '/'
      ? cleanPath
      : `${cleanPath}/`;

    return queryParams ? `${path}?${queryParams}` : path;
  };
}
