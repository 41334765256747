export const OBJECT_FIRST = {
  LINK: {
    X: 'https://x.com/object_first',
    LINKED_IN: 'https://www.linkedin.com/company/objectfirst',
    YOUTUBE: 'https://www.youtube.com/channel/UCIi-WYFWGZlseKlrrCTtYuA',
    INSTAGRAM: 'https://www.instagram.com/objectfirst_/',
  },
  PHONE: {
    GLOBAL: '+1 844 569-0653',
    EMEA: '+44 800 031-5303',
  },
  EMAIL: {
    SUPPORT: 'support@objectfirst.com',
    SECURITY: 'security@objectfirst.com',
  },
};
