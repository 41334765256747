type Fields = Record<string, string[]>

export default class FormValidationError extends Error {
  public fields: Fields;

  constructor(fields: Fields) {
    super('Server validation error');
    this.fields = fields;
  }
}
