<template>
  <footer data-component-name="TheFooter">
    <div class="wrapper">
      <ul class="columns">
        <li class="column">
          <AppLogo variant="white" />
          <SocialLinks />
        </li>

        <li class="column">
          <ul class="nav-list">
            <li
              v-for="item of column1"
              :key="item.path"
              class="nav-item"
            >
              <NavItem
                :to="localePathWithSlash(item.path)"
                size="large"
                variant="reversed"
              >
                {{ $t(item.i18nKey) }}
              </NavItem>
            </li>
          </ul>
        </li>

        <li class="column">
          <ul class="nav-list">
            <li
              v-for="{ item, children } in column2"
              :key="item.path"
              class="nav-item"
            >
              <strong>{{ $t(item.i18nKey) }}</strong>

              <ul class="sub-list">
                <li
                  v-for="child of children"
                  :key="child.path"
                  class="sub-item"
                >
                  <NavItem
                    :to="localePathWithSlash(child.path)"
                    size="small"
                    variant="reversed"
                  >
                    {{ $t(child.i18nKey) }}
                  </NavItem>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li class="column">
          <ul class="nav-list">
            <li
              v-for="{ item, children } in column3"
              :key="item.path"
              class="nav-item"
            >
              <strong>{{ $t(item.i18nKey) }}</strong>

              <ul class="sub-list">
                <li
                  v-for="child of children"
                  :key="child.path"
                  class="sub-item"
                >
                  <NavItem
                    :to="localePathWithSlash(child.path)"
                    size="small"
                    variant="reversed"
                  >
                    {{ $t(child.i18nKey) }}
                  </NavItem>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <p class="copyright">
        &copy; {{ year }} Object First (US) Inc. | <LinkPrivacyNotice token="link-reversed" /> | <LinkDSAR token="link-reversed" />
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
// components
import AppLogo from '~/components/redesign/AppLogo.vue';
import SocialLinks from '~/components/redesign/SocialLinks.vue';
import NavItem from '~/components/redesign/NavItem.vue';
import LinkPrivacyNotice from '~/components/redesign/LinkPrivacyNotice.vue';
import LinkDSAR from '~/components/redesign/LinkDSAR.vue';

// composable
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

// constants
import { PATHS } from '~/constants';
import { OBJECT_FIRST } from '~/constants/contacts';

const column1 = [
  PATHS.PRODUCT,
  PATHS.PARTNERS,
  PATHS.BLOG,
  { path: PATHS.ACES_PROGRAM.path, i18nKey: 'Aces' },
  PATHS.GUIDES,
  {
    path: OBJECT_FIRST.LINK.YOUTUBE,
    i18nKey: 'Videos',
  },
  PATHS.EVENTS,
  PATHS.HOW_TO_BUY,
  PATHS.REQUEST_DEMO,
];

const column2 = [
  {
    item: { ...PATHS.RESOURCES, i18nKey: 'Resource_Library' },
    children: [
      PATHS.WHITE_PAPERS,
      { ...PATHS.TECHNICAL_PAPERS, i18nKey: 'Solution_Briefs' },
      PATHS.ANALYST_REPORTS,
      PATHS.CASE_STUDIES,
    ],
  },
  {
    item: PATHS.NEWSROOM,
    children: [
      PATHS.IN_THE_NEWS,
      PATHS.PRESS_RELEASES,
      PATHS.PRODUCT_REVIEWS,
    ],
  },
];

const column3 = [
  {
    item: PATHS.ABOUT_COMPANY,
    children: [
      { ...PATHS.ABOUT_COMPANY, i18nKey: 'About_Us' },
      PATHS.CAREERS,
      PATHS.CONTACTS_US,
      PATHS.MEDIA_KIT,
      PATHS.TRUST_CENTER,
      PATHS.LEGAL,
    ],
  },
  {
    item: PATHS.GET_HELP,
    children: [
      PATHS.SUPPORT,
      { ...PATHS.INSTALLATION, i18nKey: 'Installation_Guide' },
      { ...PATHS.GET_HELP, i18nKey: 'Help_Center' },
      { ...PATHS.VULNERABILITY_POLICY },
    ],
  },
];

const year = new Date().getFullYear();
const localePathWithSlash = useLocalePathWithSlash();
</script>

<style scoped lang="scss">
@import "$/functions/token";
@import "$/mixins/flex";
@import "$/mixins/typography";
@import "$/mixins/size";
@import "$/mixins/media";
@import "$/variables/color-roles";

[data-component-name="TheFooter"] {
  padding: 8rem 2rem;
  background-color: token('surf-cont-dark');

  @include tablet {
    padding: 5rem 2rem;
  }

  @include mobile {
    padding: 3rem 1rem;
  }

  .wrapper {
    @include flex-stretch-sb;
    flex-direction: column;
    gap: 4rem;

    @include wrapper(76rem);

    @include mobile {
      gap: 2.5rem;
    }

    ul.columns {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(1, 1fr);
      gap: 2rem;

      @include tablet {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
        gap: 2.5rem 2.5rem;
      }

      @include mobile {
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem 2.5rem;
      }

      li.column {
        &:nth-child(1) {
          @include flex-stretch-start;
          flex-direction: column;

          @include tablet {
            @include flex-start-start;

            grid-column: 1/2;
            grid-row: 1/2;
          }

          @include mobile {
            grid-row: 1/2;
          }

          [data-component-name="AppLogo"] {
            align-self: flex-start;
            margin-bottom: 2.5rem;

            @include tablet {
              margin-bottom: 1rem;
            }
          }
        }

        &:nth-child(2) {
          @include tablet {
            grid-column: 1/2;
            grid-row: 2/4;
          }

          @include mobile {
            grid-row: 2/3;
          }
        }

        &:nth-child(3) {
          @include tablet {
            grid-column: 2/3;
            grid-row: 1/3;
          }

          @include mobile {
            grid-column: 1/2;
            grid-row: 3/4;
          }
        }

        &:nth-child(4) {
          @include tablet {
            grid-column: 2/3;
            grid-row: 3/5;
          }

          @include mobile {
            grid-column: 1/2;
            grid-row: 4/5;
          }
        }

        &:not(:nth-child(1)):not(:nth-child(2)) {
          ul.nav-list {
            li.nav-item:not(:first-of-type) {
              margin-top: 2rem;
            }
          }
        }

        ul.nav-list {
          @include flex-stretch-start;
          flex-direction: column;

          li.nav-item {
            @include tablet {
              @include fluid-max-width(17.5rem);
            }

            strong {
              display: block;
              padding: 1rem;

              color: token('text-reversed');
              @include subtitle-2;
            }

            ul.sub-list {
              @include flex-stretch-start;
              flex-direction: column;
            }
          }
        }
      }
    }

    p.copyright {
      margin: 0 auto;
      color: token('text-tertiary');
      text-align: center;
      @include body-3;

      [data-component-name="LinkPrivacyNotice"] {
        @include link-text-3;
      }
    }
  }
}
</style>
