export default defineNuxtPlugin(() => {
  const nuxt = useNuxtApp();
  const { locale } = nuxt.$i18n;

  watch(locale, (newLocale) => {
    useGqlHeaders({
      'Locale': newLocale || 'en',
      'Content-Type': 'application/json',
    });
  }, {
    immediate: true,
  });

  useGqlError((err) => {
    if (process.env.NUXT_SITE_ENV!== 'production') {
      for (const gqlError of err.gqlErrors) {
        console.error('[nuxt-graphql-client] [GraphQL error]', {
          client: err.client,
          statusCode: err.statusCode,
          operationType: err.operationType,
          operationName: err.operationName,
          gqlError
        })
      }
    }
  })
})
