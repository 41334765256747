export const SUBSCRIBE = '/subscribe';
export const REPORT = '/report';
export const ACTIVITY = '/activity';
export const SEARCH = '/search';
export const DEMO = '/demo';
export const DEMO_SHORT = '/demo-short';
export const BECOME_PARTNER = '/become-partner';
export const CONNECT_CHANNEL_MANAGER = '/connect-channel-manager';
export const WHITE_PAPER = '/white-paper';
export const VSA = '/vsa';
export const OFFLINE_BUNDLE = '/offline-bundles/request';

export const LICENSES = '/licenses';
export const LICENSES_DOWNLOAD = `${LICENSES}/download`;
export const LICENSES_DOWNLOAD_URL = `${LICENSES_DOWNLOAD}/url`;

export const PRE_RECORDED_DEMO = '/pre-recorded-demo';
export const PRE_RECORDED_DEMO_USER_SESSIONS = `${PRE_RECORDED_DEMO}/user-sessions`;
export const PRE_RECORDED_DEMO_ACCESS_LINK = `${PRE_RECORDED_DEMO}/access-link`;
export const PRE_RECORDED_DEMO_ACCESS_LINK_VERIFY = `${PRE_RECORDED_DEMO_ACCESS_LINK}/verify`;

export const ZERO_BOUNCE = '/zero-bounce/validate'

export const SERIAL_NUMBER_EXIST = (serialNumber: string) => `/ogou/servers/${serialNumber}/exists`;
